import styled from '@emotion/styled'

import { builderLightText } from '@/legacy/blocks/utils'
import { Image } from '@/legacy/components/Image'
import { CONFIG } from '@/ui/shared/styles'

import { RichText } from '../RichText'
import { BodyM, BodyP, type TypographyColorModifier } from '../Typography'

const DisclaimerStyles = styled.div`
  display: flex;
  align-items: center;

  .disclaimer__content {
    margin-left: 1rem;
  }

  ${CONFIG.media.mobileMd} {
    .disclaimer__content {
      max-width: calc(100vw - 6rem);
    }
  }
`

interface IconProps {
  alt: string
  url: string
  'aria-hidden'?: boolean
}

interface DisclaimerProps {
  content: string
  hasSmallFontSize?: boolean
  icon?: IconProps
  colorModifier?: TypographyColorModifier
}

const Disclaimer = ({ content, hasSmallFontSize, icon, colorModifier }: DisclaimerProps) => {
  const TypographyBody = hasSmallFontSize ? BodyP : BodyM
  const lightText = builderLightText(colorModifier)

  return (
    <DisclaimerStyles>
      {icon && (
        <Image
          source={icon.url}
          aria-hidden={icon['aria-hidden']}
          alt={icon.alt}
          width={48}
          height={48}
        />
      )}
      {content && (
        <RichText
          light={lightText}
          tag="div"
          className={'disclaimer__content'}
          dark="high"
          content={content}
          typography={TypographyBody}
        />
      )}
    </DisclaimerStyles>
  )
}

export default Disclaimer
