import { Container, type BackgroundColor, type ContainerProps } from '@/legacy/components/Container'
import type { ContainerMaxWidth, Space } from '@/legacy/components/Container/Container'
import { Disclaimer } from '@/legacy/components/Disclaimer'
import type { TypographyColorModifier } from '@/legacy/components/Typography'
import { clearBuilderProps } from '@/legacy/core/utils/dom/clearBuilderProps'
import type { BuilderImage } from '@/legacy/models/BuilderImage'

export interface DisclaimerBlockProps {
  id?: string
  content: string
  background: BackgroundColor
  backgroundColor?: string
  hasSmallFontSize?: boolean
  image?: BuilderImage
  containerSize?: ContainerMaxWidth
  space: Space
  colorModifier?: TypographyColorModifier
}

const DisclaimerBlock = ({
  id,
  image,
  content,
  background,
  backgroundColor,
  hasSmallFontSize,
  containerSize,
  space = 'm',
  colorModifier,
  ...disclaimerProps
}: DisclaimerBlockProps) => {
  clearBuilderProps(disclaimerProps)

  const containerProps: ContainerProps = {
    id,
    backgroundColor: background,
    backgroundColorHex: backgroundColor,
    spaceIn: 'm',
    spaceOut: space,
    maxWidth: containerSize,
  }

  return (
    <Container {...containerProps}>
      <Disclaimer
        colorModifier={colorModifier}
        content={content}
        hasSmallFontSize={hasSmallFontSize}
        icon={image}
      />
    </Container>
  )
}

export default DisclaimerBlock
